<template>
    <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="date"
                :label="label"
                prepend-icon="mdi-calendar"
                readonly
                filled
                rounded
                dense
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
            </template>
            <v-date-picker
            v-model="date"
            no-title
            scrollable
            
            >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu = false"
            >
                Cancelar
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="guardar_fecha(date)"
            >
                OK
            </v-btn>
            </v-date-picker>
        </v-menu>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
            required:true
        }
    },
    data(){
        return {
            date: null,
            menu: false,
        }
    },
    methods: {
        guardar_fecha(date){
            this.$refs.menu.save(date)
            this.$emit('fecha',date)
        }
    }
}
</script>