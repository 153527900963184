<template>
    <v-app>
        <v-btn @click="show_placas()"> Show placas</v-btn>

        <v-btn @click="finx_placas()"> Fix placas</v-btn>
    </v-app>
</template>
<script>
export default {
    data(){
        return {
            placas: [],
        }
    },
    created(){
        this.get_placas();
    },
    methods: {
        show_placas(){
            console.log(this.placas);
        },
        async finx_placas(){

            for (let index = 0; index < this.placas.length ; index++) {
               
                
                try {
                    const fix_tramites_placa= await this.$store.dispatch('llamado_patch',{
                        url:`https://siett.educandote.co/types/collections/V1_SIETT_DOCUMENT_V3_3/documents/${this.placas[index].id}`,
                        body: {
                            Tipo_de_vehiculo: "MOTOCICLETA"
                        }
                        ,tipo_header: "indexador"
                    });

                    console.log(fix_tramites_placa);

                } catch (error) {
                    console.log(error);
                }
                
            }
        },
        async get_placas(){

            let rango = 'LYG' // LYG53-LYG99

            for (let index = 53; index < 100 ; index++) {
                let placa = `${rango}${index}`;

                try {
                    const get_tramites_placa= await this.$store.dispatch('llamado_get',{
                        url:`https://siett.educandote.co/types/collections/V1_SIETT_DOCUMENT_V3_3/documents/search?q=${placa}&query_by=Placa&filter_by=Placa:["${placa}"]&per_page=250&page=1`,
                        tipo_header: "indexador"
                    });

                    console.log(get_tramites_placa);

                    for (let index = 0; index < get_tramites_placa.data.hits.length; index++) {
                        
                        this.placas.push({
                            id: get_tramites_placa.data.hits[index].document.id,
                            placa: placa
                        });
                        
                    }

                } catch (error) {
                    console.log(error);
                }
                
            }

            
            

            
        }
    }
}
</script>