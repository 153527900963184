<template>
    <v-app>
        <v-main >
            <v-sheet class="ma-5" >
                <v-toolbar color="indigo darken-4" dark>
                    <!--
                    <v-btn @click="$router.go(-1)" icon dark>
                        <v-icon> mdi-arrow-left </v-icon>
                    </v-btn>
                    -->
                    
                    <v-toolbar-title>Reportes</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>
                        <v-btn outlined dark @click="GET_PLACAS(users_selected)">Generar reporte</v-btn>
                        <vue-excel-xlsx v-if="load==false" class="mx-2"

                            :data="reporte"
                            :columns="columns"
                            :filename="`Reporte : ${fecha.inicial} - ${fecha.final}`"
                            :sheetname="'Reporte'" 

                            >
                            <v-btn class="mt-1" block rounded="" dark color="white" style="color:blue" >
                                <span>
                                    Descargar
                                </span>
                                <v-icon>
                                    mdi-microsoft-excel
                                </v-icon>
                            </v-btn>
                        </vue-excel-xlsx>
                    </v-toolbar-title>
                </v-toolbar>
                <Load :load="load"></Load>
                <v-row class="mx-5">
                    <v-col>
                        <Fechas :label="'Fecha inicio'" @fecha="fecha.inicial=$event" />
                    </v-col>
                    <v-col>
                        <Fechas :label="'Fecha finalización'" @fecha="fecha.final=$event" />
                    </v-col>
                </v-row>
                <v-row class="mx-5">
                    <v-col cols="6">
                        <v-autocomplete :loading="load_users" label="Empresa" append-icon="mdi-magnify" @click:append="GET_USUARIOS(tenants_selected)" multiple prepend-icon="mdi-home" v-model="tenants_selected" :items="tenants_available" filled rounded dense ></v-autocomplete>
                    </v-col>
                    <v-col cols="6" v-show="search_tenants_selected" >
                        <v-autocomplete append-icon="mdi-magnify" @click:append="GET_PLACAS(users_selected)" item-value="nombre" item-text="nombre" multiple prepend-icon="mdi-account" v-model="users_selected" :items="users" filled rounded dense label="Empleados"  ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row class="mx-5">
                    <v-col cols="12" sm="4" >
                        <v-card elevation="8" rounded="xl">
                            
                            <br>
                            
                            <h3 style="text-align:center"> Cantidad de placas: {{contador_placas}} <v-icon>mdi-car-side</v-icon>   </h3> 
                            <br>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="4" >
                        <v-card elevation="8" rounded="xl">
                            <br>
                            <h3 style="text-align:center"> Cantidad de tramites: {{contador_tramites}} <v-icon>mdi-card-account-details-outline</v-icon> </h3>
                            <br>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="4" >
                        <v-card elevation="8" rounded="xl">
                            <br>
                            <h3 style="text-align:center"> Cantidad de imagenes: {{contador_imagenes}} <v-icon>mdi-image-multiple-outline</v-icon> </h3>
                            <br>
                        </v-card>
                    </v-col>
                    
                </v-row>
                <v-row >
                    <v-col>
                        
                    </v-col>
                </v-row>
            </v-sheet>
        </v-main>
    </v-app>
</template>
<script>
import Fechas from '../../components/atomos/Fechas.vue'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from 'vue'
Vue.use(VueExcelXlsx)


export default {
    components: {
        Fechas
    },
    data(){
        return {

            show_report: false,
            
            fecha: {
                inicial: null,
                final: null,
            },

            load: null,

            contador_placas: 0,
            contador_tramites: 0,
            contador_imagenes: 0,

            //index: 0,
            reporte: [],
            users_selected: [],
            users: [],
            
        
            columns: [
            {field: 'Placa',label:'Placa'},
            {field: 'Rango_placa',label:'Rango'},
            {field: 'Numero_rango_placa',label:'Numero rango placa'},
            {field: 'Parque_automotor',label:'Parque automotor'},
            //{value: 'Parque_auto_motor',text:'Parque automotor'},
            {field: 'Tipo_de_tramite',label:'Tipo de tramite'},
            {field: 'Fecha',label:'Fecha'},
            {field: 'Tipo_de_vehiculo',label:'Vehiculo'},
            {field: 'Documento_del_propietario',label:'Documento del propietario'},
            {field: 'Observaciones',label:'Observaciones'},
            {field: 'estatus',label:'Estatus'},
            {field: 'autor',label:'Autor'},
            ],
            tenants_available: [
                'AUDICOL',
                'SIETT',
            ],
            tenants_selected: [],
            search_tenants_selected: false,
            load_users: false
        }
    },
    created(){
        //this.GET_USUARIOS();
    },
    methods: {
        fn_show_report(){
            console.log(this.reporte);
            console.log('x')
        },
        async GET_USUARIOS(tenants_selected){
            //Select al parse de las areas que tengan habilitado el módulo de digitalizacion
            //permiso_1
            this.load_users = true;
            try {
                let buscar_sectores = JSON.stringify({"tenant": { "$in": [...tenants_selected]}});
                                                                                 
                buscar_sectores = encodeURIComponent(buscar_sectores);

                const response_sectores = await this.$store.dispatch('llamado_get',{
                    url: `https://siett.educandote.co/parse/classes/prueba01Sector?where=${buscar_sectores}`,
                    tipo_header: "parse"
                });

                let sectores = [];

                for (let index = 0; index < response_sectores.data.results.length; index++) {
                    sectores.push(response_sectores.data.results[index].objectId);
                }

                let buscar = JSON.stringify({"sector": { "$in": [...sectores]}});
                                                                
                                                
                buscar = encodeURIComponent(buscar);

                const response_permisos = await this.$store.dispatch('llamado_get',{
                    url: `https://siett.educandote.co/parse/classes/prueba01PermisosUsuario?where=${buscar}`,
                    tipo_header: "parse"
                });

                console.log(response_permisos);

                let usuarios = [];
                for (let index = 0; index < response_permisos.data.results.length; index++) {

                    let rangos = [];
                    if(response_permisos.data.results[index].permiso_1.supervisor == true){
                        rangos.push('Supervisor');  
                    }

                    if(response_permisos.data.results[index].permiso_1.empleado == true){
                        rangos.push('Empleado');                    
                    }

                    let buscar_usuarios = JSON.stringify(    {"sector": response_permisos.data.results[index].sector,
                                                                "rango": {
                                                                    "$in": [...rangos]
                                                            }
                                                });

                    buscar_usuarios = encodeURIComponent(buscar_usuarios);
                    
                    const response_usuarios = await this.$store.dispatch('llamado_get',{
                        url: `https://siett.educandote.co/parse/classes/prueba03Usuarios?where=${buscar_usuarios}`,
                        tipo_header: "parse"
                    });

                    console.log(response_usuarios);
                    usuarios = [...usuarios, ...response_usuarios.data.results];

                }

                console.log(usuarios);

                this.users = [...usuarios];

                this.search_tenants_selected = true;
            } catch (error) {
                console.log(error);
                this.$store.commit(
                "error",
                "Ha ocurrido un error inesperado al consultar los empleados de la empresa... "+error.message
                );
            }

            this.load_users = false;
            
        },

        async GET_PLACAS(users){


            this.load = true;

            this.reporte = [];
            console.log(this.fecha);
            //Placas.
            //Tramites por placa
            //Imagenes por tramite

            //&filter_by=fecha_creacion_int:[970722000000..1672466400000];

                try {
                    let fecha = {
                    inicial: Date.parse(new Date(this.fecha.inicial)),
                    final: Date.parse(new Date(this.fecha.final+' 23:59')),
                }

                console.log(fecha);


                

                const get_reporte = await this.$store.dispatch('llamado_get',{
                    url:`https://siett.educandote.co/types/collections/V1_SIETT_DOCUMENT_V3_3/documents/search?q=${'HABILITADA'}&query_by=estatus&group_by=Placa&filter_by=fecha_creacion_int:[${fecha.inicial}..${fecha.final}]&&autor:=[${users}]&group_limit=99&per_page=250`,
                    tipo_header: "indexador"
                });

                console.log(get_reporte);

                //let contador = 0;
                this.contador_placas = get_reporte.data.found;
                let pages = parseInt(get_reporte.data.found/250)+1;

                //get_reporte.data.grouped_hits.length.length

                for (let index = 1; index <= pages; index++) {
                    const get_reporte_per_page = await this.$store.dispatch('llamado_get',{
                        url:`https://siett.educandote.co/types/collections/V1_SIETT_DOCUMENT_V3_3/documents/search?q=${'HABILITADA'}&query_by=estatus&group_by=Placa&filter_by=fecha_creacion_int:[${fecha.inicial}..${fecha.final}]&&autor:=[${users}]&group_limit=99&per_page=250&page=${index}`,
                        tipo_header: "indexador"
                    });
                    console.log(get_reporte_per_page.data);
                    this.index = index;
                    

                    for (let index2 = 0; index2 < get_reporte_per_page.data.grouped_hits.length; index2++) {
                        
                        

                        this.contador_tramites = this.contador_tramites + get_reporte_per_page.data.grouped_hits[index2].hits.length;
                        
                        try {
                            
                            for (let index3 = 0; index3 < get_reporte_per_page.data.grouped_hits[index2].hits.length; index3++) {
                                
                                
                               
                                //let iameges_por_tramite = await this.contar_imagenes_tramite(get_reporte_per_page.data.grouped_hits[index2].hits[index3].document, users);
                                const document = get_reporte_per_page.data.grouped_hits[index2].hits[index3].document;
                                this.reporte.push(document);
                            }

                            
                        } catch (error) {
                            console.log(error);

                            this.$store.commit(
                                    "error",
                                    "Está ocurriendo un error mientras se genera el reporte "+error.message
                            );
                        }
                    }   
                }

                
                //this.contador_tramites = contador;
                console.log(get_reporte.data.found) // Cantidad de placas
                //console.log(contador); //Cantidad de tramites por placas
                //Imagenes por placas

                this.$store.commit(
                        "exitoso",
                        "Reporte generado de forma exitosa. Por favor realiza click en el botón de 'Descargar' que acaba de aparecer en la parte superior derecha de la pantalla "
                );

            } catch (error) {
                console.log(error);
                this.$store.commit(
                        "error",
                        "Ha ocurrido un error inesperado en el momento de generar el reporte "+error.message
                );
            }

            this.load = false;
        },

        async contar_imagenes_tramite(tramite,users){
            try {
                console.log(tramite);
                const id_busqueda = tramite.id;

                let cantidad = 0;

                const get_archivos_placa = await this.$store.dispatch('llamado_get',{
                    url:`https://siett.educandote.co/types/collections/IMAGENES_V1_SIETT_DOCUMENT_V3_2/documents/search?q=${id_busqueda}&query_by=${'id_busqueda'}&filter_by=tipo:[jpg,png]&&id_busqueda:[${id_busqueda}]&&autor:=[${users}]&per_page=${250}&sort_by=order:asc`,
                    tipo_header: "indexador"
                });

                this.contador_imagenes = this.contador_imagenes + get_archivos_placa.data.found;
                cantidad = get_archivos_placa.data.found;
                console.log(get_archivos_placa);
                let pages = parseInt(get_archivos_placa.data.found/250)+1;

                if(pages>1){

                    for (let index = 2; index <= pages; index++) {
                        let get_archivos_placa_v2 = await this.$store.dispatch('llamado_get',{
                            url:`https://siett.educandote.co/types/collections/IMAGENES_V1_SIETT_DOCUMENT_V3_2/documents/search?q=${id_busqueda}&query_by=${'id_busqueda'}&filter_by=tipo:[jpg,png]&&id_busqueda:[${id_busqueda}]&&autor:=[${users}]&per_page=${250}&page=${index}&sort_by=order:asc`,
                            tipo_header: "indexador"
                        });
                        this.contador_imagenes = this.contador_imagenes + get_archivos_placa_v2.data.found;
                        cantidad = cantidad + get_archivos_placa_v2.data.found;
                    }

                    
                }



                return cantidad;

            } catch (error) {
                console.log(error);
                this.$store.commit(
                    "error",
                    "Está ocurriendo un error mientras se cuentan las imagenes del reporte "+error.message
                );
            }

            return 0;
        },

        async PLACAS_POR_DIGITALIZADOR(){
            //Agrupar los id_busqueda en las imagenes

            //Agrupar las placas en los tramites

            //Relacionar ambas querys

            //

            //Crear una nueva API Indicando quién creó el tramite de la placa
        },

        async GET_IMAGENES_POR_PLACA(){
            try {
                console.log('x')
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>