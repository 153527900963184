<template>
    <v-app>
        <v-btn @click="COUNT_PDF">START</v-btn>
        <v-btn @click="show">show</v-btn>
    </v-app>
</template>
<script>
const axios = require("axios").default;
export default {
    data(){
        return {
            pdfs: [],
            pdfs_with_error: [],
        }
    },
    created(){
        this.GET_DATA_PARSE();
        
    },
    methods: {
        async show(){
            console.log(this.pdfs_with_error);
        },
        async GET_DATA_PARSE(){
            try {
                this.pdfs = [];
                const count = encodeURIComponent('1');
                const limit = encodeURIComponent('13361');
                //buscar_login = encodeURIComponent(buscar_login);

                let response = await this.$store.dispatch('llamado_get',{
                    url: "https://siett.educandote.co/parse/classes/IMAGENESV2_V1_SIETT_DOCUMENT_V3_2?limit="+limit+"&count="+count,
                    tipo_header: "parse"
                });


                console.log(response);

                this.pdfs = [...response.data.results];
            } catch (error) {
                console.log(error);
            }
        },

        async COUNT_PDF(){
            try {
                const limit = this.pdfs.length

                for (let index = 0; index < limit; index++) {
                    const body = JSON.parse(this.pdfs[index].body);

                    const carpeta = body.token;
                    const nombre_archivo = `${body.nombre}.pdf`;

                    let form_data = new FormData();
                    
                    form_data.append("texto",  carpeta);
                    form_data.append("nombre", nombre_archivo);
                    this.$store.commit("auth");
                    const response = await axios.post(
                    "https://drive1.educandote.co/siett/countPDF.php",
                    form_data,
                    {
                        header: {
                        "Content-Type": "multipart/form-data",
                        Authorization: this.$store.state.header,
                        },
                    });

                    console.log(response);
                    let cantidad_imagenes_pdf = 0
                    if(response.data.message!=''){
                        cantidad_imagenes_pdf = parseInt(response.data.message);
                    }else{
                        this.pdfs_with_error.push(this.pdfs[index])
                    }
                    

                    let body_imagenes = "";
                    let name_images = [];
                  
                    for (let index = 0; index < cantidad_imagenes_pdf; index++) {
                      
                      body_imagenes = body_imagenes+`{"id_busqueda": "${body.id_busqueda}", "id_carpeta": "${body.id_carpeta}", "autor": "${body.autor}", "estado_imagen": "${'INHABILITADA'}", "meta_datos": "", "nombre": "${index+1}", "nombre_sector": "${body.nombre_sector}", "sector": "${body.sector}", "tipo": "${'png'}", "token": "${body.token}", "fecha_creacion_int": ${body.fecha_creacion_int}, "order": ${body.order}, "extended_properties": "{}","imagenes_por_archivo": "1"}`+'\n';
                      name_images.push(index+1);
                    }

                    console.log(body_imagenes);
                    
                    if(body_imagenes!=""){
                        await this.set_images(body_imagenes);

                        const result = await this.get_ids_folder(body.token);
                        const collections = 'V1_SIETT_DOCUMENT_V3_2'
                        const archivos = await this.$store.dispatch('llamado_post',{
                            url:`https://siett.educandote.co/parse/classes/IMAGENESV3_${collections}`,
                            body: {
                                id_images:result.id_images,
                                id_pdf: result.id_pdf,
                                folder: body.token,
                                method: "PATCH",
                                url_typesense: `https://siett.educandote.co/types/collections/IMAGENES_${collections}/documents/`          
                                ,date: body.fecha_creacion_int,
                                body_for_enable: {
                                estado_imagen: 'habilitada'
                                },
                                body_for_disable: {
                                estado_imagen: 'INHABILITADA'
                                },
                                name_images: name_images,
                                name_pdf: body.nombre,

                            
                            },
                            tipo_header: "parse"
                        });

                        console.log(archivos);
                    }
                    

                    //Hay que hacer un query para encontrar aquellas filas en la carpeta IMAGENESV3_V1_SIETT_DOCUMENT_V3_2
                    // que no tengan id_images y id_pdf

                   
                    
                }

            } catch (error) {
                console.log(error);
            }
        },
        async get_ids_folder(token){
     

            let result = {
            id_images: [],
            id_pdf: ""
            };

            try {
            
            console.log(token);
            const response = await this.$store.dispatch('llamado_get',{
                    url:`https://siett.educandote.co/types/collections/IMAGENES_${'V1_SIETT_DOCUMENT_V3_2'}/documents/search?q=${token}&query_by=${'token'}&filter_by=token:[${token}]&per_page=${250}&sort_by=order:asc`,
                    tipo_header: "indexador"
            });

            
            for (let index = 0; index < response.data.hits.length; index++) {

                if(response.data.hits[index].document.tipo=='pdf'){
                result.id_pdf = response.data.hits[index].document.id;
                }else{
                result.id_images.push(response.data.hits[index].document.id);
                }
                
                
            }
            console.log(result);

            //console.log(ids);

            

            } catch (error) {
            console.log(error);
            }
            return result;
        },
        async set_images(body){

            console.log(body);

            try {
                const collections = 'V1_SIETT_DOCUMENT_V3_2'
                const imagenes = await this.$store.dispatch('llamado_post',{
                    url:`https://siett.educandote.co/types/collections/IMAGENES_${collections}/documents/import?action=create`,
                    body: body,
                    tipo_header: "indexador"
                });

                console.log(imagenes)
            } catch (error) {
                console.log(error);
            }

        },
    },
}
</script>